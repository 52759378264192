// src/App.js
import React from 'react';
// import './App.css';

import HomePage from './components/HomePage';
const App = () => {
  return (
    <HomePage />
      
  );
};

export default App;
